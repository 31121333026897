<template>
  <div class="px-4">
    <nuxt-link
      :to="props.to"
      class="block rounded-md px-3 py-1 text-gray-700 transition-all dark:text-gray-400"
      :class="{
        '!cursor-not-allowed opacity-30': props.disabled,
        'hover:bg-gray-200 hover:dark:bg-gray-800': !props.disabled,
        '!bg-gray-300 dark:!bg-gray-700': isActive,
      }"
      @click.capture="onClick"
    >
      <slot />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  to: string
  disabled?: boolean
}>()

const route = useRoute()
const isActive = computed(() => {
  if (props.to === "/") {
    return route.path === props.to
  }

  return route.path.startsWith(props.to)
})

function onClick(e: MouseEvent) {
  if (props.disabled) {
    e.stopPropagation()
    e.preventDefault()
  }
}
</script>
