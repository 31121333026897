<template>
  <div
    v-if="unsignedAgreements.length"
    class="mb-5 flex flex-col gap-2"
  >
    <v-notification
      v-for="agreement of unsignedAgreements"
      :key="agreement.id"
      type="warning"
      class="cursor-pointer"
      @click="openAgreementModal(agreement)"
    >
      Required agreement <b>«{{ agreement.name }}»</b> isn't signed. Click here to review and sign it.
    </v-notification>

    <v-modal-sign-agreement
      v-if="activeAgreement"
      ref="agreementModal"
      :agreement="activeAgreement"
    />
  </div>
</template>

<script setup lang="ts">
import type { Agreement } from "core/types/api"
import { VModalSignAgreement } from "#components"

const { unsignedAgreements } = storeToRefs(useAgreementsStore())

const agreementModal = ref<InstanceType<typeof VModalSignAgreement>>()
const activeAgreement = ref<null | Agreement>(null)
async function openAgreementModal(agreement: Agreement) {
  activeAgreement.value = agreement
  await nextTick()
  agreementModal.value?.show()
}
</script>
