<template>
  <div>
    <div class="mb-1.5 px-5 text-lg font-medium">{{ props.title }}</div>
    <div class="flex flex-col items-stretch gap-1">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string
}>()
</script>
